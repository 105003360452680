import Navigation from "./Navigation/Navigation";
import { Container } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NetworkChangeListener from "@shared/components/NetworkChangeListener/NetworkChangeListener";
import { FC, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getActiveOrders} from "@shared/services/orders/orders.service";
import { getDrivers } from "@shared/services/drivers/drivers.service";
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    contentContainer: {
      height: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    alertMessage: {
      backgroundColor: "yellow",
      color: "red", 
      fontWeight: "bold", 
      fontSize: "18px",
      padding: "5px", 
      textAlign: "center",
      margin: "5px", 
      borderRadius: "5px",
    },
  })
);

const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const loadAllData = async () => {
      const { orders, drivers} = await Promise.all([
        getActiveOrders(),
        getDrivers(),
      ]).then(([orders, drivers]) => ({ orders, drivers}));
      if(orders.length/drivers.filter(driver => driver.pauseStatus === null).length >= 1.5)
      {
        setShowDialog(true);       
      }else
      {
        setShowDialog(false)
      }
    };

    loadAllData();
  }, []);  
  return (
    <Container disableGutters className={classes.container}>
      <NetworkChangeListener />

      <main className={classes.contentContainer}>
      {showDialog && (
        <div className={classes.alertMessage}>
          Многу нарачки во моментов! Искористи гужва 😉
        </div>
        )}
        <div className={classes.content}>
          <Outlet />
        </div>
       
        <div className={classes.toolbar} />
      <Navigation/>
      </main>

    </Container>
  );
};

export default Layout;
