import { FC, forwardRef } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    minWidth: "10rem",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: "100%",
  },
  rounded: {
    borderRadius: theme.shape.borderRadius,
  },
  circular: {
    borderRadius: "25px",
  },
  backgroundNormal: {
    backgroundColor: "#888",
  },
  backgroundWhite: {
    backgroundColor: "#fff",
  },
  backgroundWarning: {
    backgroundColor: "burlywood",
  },
  backgroundDanger: {
    backgroundColor: "indianred",
  },
  backgroundSuccess: {
    backgroundColor: "#00e676",
  },
  backgroundSalmon: {
    backgroundColor: "#f98a86",
  },
}));

interface CardProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  innerRef?: React.Ref<any>;
  background?: "white" | "normal" | "none" | "danger" | "warning" | "success" | "salmon";
  variant?: "square" | "rounded" | "circular";
}

const Card: FC<CardProps> = ({
  innerRef,
  background = "none",
  variant = "rounded",
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      {...rest}
      ref={innerRef}
      className={clsx(
        classes.root,
        {
          [classes.backgroundNormal]: background === "normal",
          [classes.backgroundWhite]: background === "white",
          [classes.backgroundWarning]: background === "warning",
          [classes.backgroundDanger]: background === "danger",
          [classes.backgroundSuccess]: background === "success",
          [classes.backgroundSalmon]: background === "salmon",
        },
        rest.className,
        {
          [classes.rounded]: variant === "rounded",
          [classes.circular]: variant === "circular",
        }
      )}
    >
      {children}
    </div>
  );
};

export default forwardRef<FC<CardProps>, CardProps>((props, ref) => (
  <Card innerRef={ref} {...props} />
));
